import { render, staticRenderFns } from "./realtyClassify.vue?vue&type=template&id=6dc7d896&scoped=true&"
import script from "./realtyClassify.vue?vue&type=script&lang=js&"
export * from "./realtyClassify.vue?vue&type=script&lang=js&"
import style0 from "./realtyClassify.vue?vue&type=style&index=0&id=6dc7d896&prod&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc7d896",
  null
  
)

export default component.exports